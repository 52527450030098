import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} actes
 */
export default class MediaTransformer extends Transformer {

    table = 'media'

    transform (item) {
        return {
            "media_id": item.media_id,
            "media_name": item.media_name,
            "media_date": item.media_date,
            "media_fk": item.media_fk,
            "media_type": item.media_type,
            "media_filename": item.media_filename
        }
    }
}
