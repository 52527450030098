import Constants from '../../config.local.js'
import MediaTransformer from '@/assets/js/dexie/transformers/MediaTransformer.js'
import Common from '../assets/js/common.js'

var CloudMedia = {

	data() {
		return {

		}
	},
	methods: {
		getMediaByFK: function(type, fk_id) {
			return this.$storage.db.t('media')
			.then(table => {
				return table.where({
					media_type: type,
					media_fk: parseInt(fk_id)
				})
			})
			.then(col => {
				return col.transform(new MediaTransformer())
			})
		},

		addMedia: async function(param) {
			if(param.id) {
				return await this.$storage.db.t('media')
				.then(table => {
					return table.update(parseInt(param.id), {
						media_name: param.name,
						media_date: param.date,
						media_valide: 1
					})
				})
			} else {
				const filename = await this.$sync.uploadFile(param.document_file)
				const media_id = Common.getNegativeId()

				return await this.$storage.db.t('media')
				.then(table => {
					return table.add({
						media_id: parseInt(media_id),
						media_fk: parseInt(param.fk),
						media_filename: filename,
						media_type: param.type,
						media_name: param.name,
						media_date: param.date,
						media_valide: 1
					})
				})
			}
		},

		deleteMedia: async function(media_id) {
			return await this.$storage.db.t('media')
				.then(table => {
					return table.where('media_id')
					.equals(parseInt(media_id))
					.invalid()
				})
		}
	}

}

export default CloudMedia