<template>
	<b-modal ref="modalDocument" @hidden="onHideModal" static>
		<template slot="modal-header" slot-scope="{ close }">
			<h2 v-if="media == null" class="mx-auto my-auto">{{ $t('media.ajouter') }}</h2>
			<h2 v-else class="mx-auto my-auto">{{ $t('media.modifier') }}</h2>
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
		</template>

		<template slot="default">

			<div class="row">
				<div class="col-12">
					<label for="name">{{ $t('media.nom') }}</label>
					<div class="form-group">
						<input
							id="name"
							type="text"
							class="form-control"
							:placeholder="$t('media.nom')"
							v-model="name"
							:class="{ 'is-invalid': errors && errors.indexOf('name') > -1 }"
						>
					</div>
				</div>

				<div class="col-12">
					<label>{{ $t('media.date') }}</label>
			        <div class="form-group">
			            <e-datepicker v-model="date" required />
			        </div>
				</div>

				<div v-if="media == null" class="col-12">
					<b-form-file
			              v-model="document_file"
			              :state="Boolean(document_file) && isValidSizeFile"
			              :placeholder="$t('media.choisir_document')"
			              :drop-placeholder="$t('fichier.drop_file')+'...'"
			        ></b-form-file>
			        <div v-show="document_file && isValidSizeFile" class="mt-3">{{ $t('fichier.selected_file') }}: {{ document_file ? document_file.name : '' }}</div>
			    </div>
			</div>

		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="checkForm()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.enregistrer') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">

	import CloudMedia from "@/mixins/CloudMedia.js"

	export default {
		name: 'ModalAddDocument',
		mixins: [CloudMedia],
		props: ['type', 'fk', 'media', 'refresh_table'],
		data () {
			return {
				name: '',
				document_file: null,
				document_limit_size: '10', // en Mo
				processing: false, 
				errors: [],
				date: new Date()
			}
		},

		methods: {
			async checkForm() {
				this.processing = true;

				if(this.name == null) {
					this.errors.push("name")
				}

				if(this.document_file == null && this.media == null) {
					this.errors.push("document_file")
				}

				if(this.errors.length > 0){
					this.processing = false
					return false
				}

				const param = {
					name: this.name,
					fk: this.fk,
					type: this.type, 
					date: this.date
				}

				if(this.media) {
					param.id = this.media.media_id
				} else {
					param.document_file = this.document_file
				}

				this.addMedia(param)
				.then(() => {
					this.successToast('toast.info_save_succes')
					this.$refs.modalDocument.hide()
					this.processing = false;
					this.$emit('update:refresh_table')
				})
				.catch((e) => {
					console.log(e);
					this.failureToast('toast.info_save_failed')
				})
			},
			
			openModal() {
				this.$refs.modalDocument.show()
			},

			onHideModal() {
				// Si c'est à l'issue d'un ajout, on clean le formulaire
				if(!this.media) this.resetModal()
			},

			resetModal() {
				this.name = ''
				this.document_file = null
				this.date = new Date()
			}
		},

		watch: {
			media (val) {
				if(val) {
					this.name = this.media.media_name
					this.date = this.media.media_date
					this.document_file = this.media.document_file
				}
				else {
					this.resetModal()
				}
			}
		},

		computed: {
 			isValidSizeFile() {
                const file_size = this.document_file.size         // Taille en octets
                const limit = this.document_limit_size * 1000000  // conversion en octets
                return file_size < limit
            }
		},
	}

</script>